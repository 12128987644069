<template>
    <div class="from_outer">
      <div>
        <el-form ref="formName" label-width="180px" :model="formLabelAlign">
          <el-row>
            <!-- <el-col :span="12"> -->
            <el-form-item
              label="物品名称："
              prop="name"
              :rules="[{ required: true, message: '请输入物品名称：' }]"
            >
              <el-input
                placeholder="请输入物品名称："
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item>
            <!-- </el-col> -->
              <!-- <el-col :span="12"> -->
            <el-form-item 
            label="所属人："
            prop="user_name"
            >
              <el-input
                placeholder="所属人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.user_name"
              ></el-input>
            </el-form-item>
            <!-- </el-col> -->
            <el-form-item 
            label="联系电话："
            prop="user_mobile"
            >
              <el-input
                placeholder="请输入联系电话"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.user_mobile"
              ></el-input>
            </el-form-item>
            <el-form-item 
            label="存放地点："
            prop="place"
            :rules="[{ required: true, message: '请输入存放地点：' }]"
            >
              <el-input
                placeholder="请输入存放地点"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.place"
              ></el-input>
            </el-form-item>

          </el-row>
        </el-form>
        <div style="text-align: center; margin: 20px 0">
          <el-button @click="on_preserve('formName')" type="primary">{{
            type_name
          }}</el-button>
          <el-button @click="route_go">返回</el-button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { irregularSaveInfo, irregularSave } from "../../assets/request/api";
  export default {
    name: "student_redacy",
    data() {
      return {
        type_name: "保存",
        //要修改的id
        id: "",
        formLabelAlign: {
          name: "",
          user_name: "",
          user_mobile: "",
          place: "",
        },
      };
    },
    created() {
      let { id } = this.$route.query;
      this.way_show(id);
    },
    methods: {
      way_time(a) {},
      way_show(id) {
        let froms = {};
        if (id) froms.id = id;
        irregularSaveInfo(froms).then((res) => {
          let { info } = res.data;
          if (id) {
            this.id = id;
            this.type_name = "修改";
            let { formLabelAlign } = this;
            let {
              name,
              user_name,
              user_mobile,
              place,
            } = info;
            formLabelAlign.id = id;
            formLabelAlign.name = name;
            formLabelAlign.user_name = user_name;
            formLabelAlign.user_mobile = user_mobile;
            formLabelAlign.place = place;
          }
        });
      },
      //点击保存
      on_preserve(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let { formLabelAlign } = this;
  
            let From = JSON.parse(JSON.stringify(formLabelAlign));
  
            irregularSave(From).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                let that = this;
                setTimeout(function () {
                  that.$router.go(-1);
                }, 1000);
              }
            });
          } else {
            return false;
          }
        });
      },
      //返回
      route_go() {
        this.$router.go(-1);
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .from {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  .form-inp {
    width: 300px;
  }
  .pop-inp {
    width: 150px;
  }
  .tag-sty {
    width: 300px;
    display: flex;
    flex-direction: column;
  }
  .selectlength {
    color: #515a6e;
    border: 1px solid #abdcff;
    background-color: #f0faff;
    padding: 10px 20px;
    font-size: 14px;
    margin: 20px 0;
    border: 5px;
    span {
      color: rgb(45, 140, 240);
    }
  }
  .search_button {
    display: flex;
    margin-bottom: 18px;
    flex-direction: column-reverse;
  }
  .botton-an {
    text-align: center;
    margin-top: 10px;
  }
  .pop-img {
    margin: 0 5px 10px 0;
    width: 40px;
    height: 40px;
  }
  </style>